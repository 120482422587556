<template>
  <div class="download-page">
    <headerBar :title="$t('xia-zai')" @back="back"></headerBar>
    <div class="main flex-column-center">
      <div class="logo">
        <img :src="logo || logoUrl" alt="" />
      </div>
      <Button @click="download(iosUrl, 'IOS')">IOS buyer download</Button>
      <Button @click="download(androidUrl, 'Android')"
        >Android buyer download</Button
      >
      <Button @click="download(sellerIosUrl, 'IOS')"
        >IOS seller download</Button
      >
      <Button @click="download(sellerAndroidUrl, 'Android')"
        >Android seller download</Button
      >
    </div>
  </div>
</template>
<script>
import headerBar from '@/components/header'
import { Button } from 'vant'
import { getUserType } from '@/utils/auth'
import { configList } from '@/api/user'
export default {
  components: {
    Button,
    headerBar
  },
  data() {
    return {
      sellerAndroidUrl: '',
      sellerIosUrl: '',
      androidUrl: '',
      iosUrl: '',
      logoUrl: require('@/assets/imgs/logo.png')
    }
  },
  computed: {
    logo() {
      return this.$store.state.logo
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      configList().then((res) => {
        let data = res.data.Items
        //商家安卓下载地址
        this.sellerAndroidUrl = data.filter((v) => {
          return v.Name == 'download_seller_android'
        })[0].Value
        //商家IOS下载地址
        this.sellerIosUrl = data.filter((v) => {
          return v.Name == 'download_seller_ios'
        })[0].Value
        //买家安卓下载地址
        this.androidUrl = data.filter((v) => {
          return v.Name == 'download_android'
        })[0].Value
        //买家IOS下载地址
        this.iosUrl = data.filter((v) => {
          return v.Name == 'download_ios'
        })[0].Value
      })
    },
    detectOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera

      // 检测 iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS'
      }

      // 检测 Android
      if (/android/i.test(userAgent)) {
        return 'Android'
      }

      // 其他操作系统
      return 'unknown'
    },
    download(link, system) {
      let a = document.createElement('a')
      a.href = link
      a.target = '_blank'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>